@import '~bootstrap/dist/css/bootstrap.css';
@import '../concept-theme/assets/fonts/mt-icons/mt-icons.css';
@import '~animate.css/animate.css';
@import '../concept-theme/assets/css/custom.css';
@import '../concept-theme/assets/css/pages-style.css';
@import './builder';

#page-content {
  padding-bottom: 2rem;
}

#header {
  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 65px;

    #logo {
      margin: 0;

      a {
        display: block;
        text-indent: -9999px;
        background: transparent url('../images/logo.png') no-repeat left center;
        background-size: auto 110px;
        height: 120px;
        width: 210px;

        @media (max-width: 767px) {
          background-image: url('../images/logo-typo.png');
          background-size: auto 30px;
          height: 35px;
          //max-width: 50%;
        }
      }
    }

    #mobile-menu-button {
      margin-top: 10px;
    }
  }
}


.alert:after {
  content: '';
}

.image-box {
  margin: 1rem;
  text-align: center;
  min-height: auto;

  img {
    width: 100%;
    max-width: 600px;
  }
}

.g-recaptcha {
  clear: both;
}

#page-header {
  margin-bottom: 50px;
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  padding: 1rem;
  width: 100%;
  z-index: 1;

  .alert {
    background: #FFF;
    margin: 0;
  }
}

.images-slider {
  margin-bottom: 0;
}

.images-slider .owl-nav {
  position: absolute;
  z-index: 555;
  bottom: 0;
  left: 0;
  padding: 20px 50px;
  background-color: #fff;
}

.images-slider .owl-nav span {
  display: none;
}

.owl-carousel {
  margin-bottom: 2rem;
}

.owl-prev {
  left: 10px;
}

.owl-next {
  right: 10px;
}

a.fancybox {
  cursor: zoom-in;
}

#footer-container {
  padding: 3rem 0;
}